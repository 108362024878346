<template>
  <div class="details-wrap" v-loading="loading">
    <div class="top-bar">
      <i class="el-icon-tickets"></i>
      <div class="title-text">平台通知</div>
    </div>
    <div>
      <h2 class="inform-title">{{data.name}}</h2>
      <div class="inform-time">{{time}}</div>
    </div>
    <div class="text-wrap">{{data.summary}}</div>
    <div v-if="data.cover" class="img-content-wrap">
        <img :src="data.cover" alt="">
    </div>
    <div class="text-wrap">
        <div v-html="data.context"></div>
    </div>
  </div>
</template>
<script>
import { URL } from "../../../config";
import { formatTime } from "../../../assets/js/utils";
export default {
  name: "platformNotificationDetails",
  data() {
    return {
      data: "",
      loading: false,
      time: ''
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let id = this.$route.query.id;
      this.loading = true;
      this.axios
        .get(URL.supplierInform.getMessageSystemDetails, { params: { id } })
        .then((res) => {
            this.data = res.data
            this.time = formatTime(res.data.createTime)
        })
        .catch((res) => {})
        .finally((res) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.details-wrap {
  width: 80%;
  background: #fff;
  margin: auto;
}
.top-bar {
  display: flex;
  padding: 10px;
  align-items: center;
  color: #3a8ee6;
  .title-text {
    margin-left: 4px;
  }
}
.inform-title {
  text-align: center;
}
.inform-time {
  text-align: center;
  font-size: 14px;
  color: #777;
  margin-bottom: 10px;
}
.img-content-wrap {
  padding: 10px 20px;
}
.img-content-wrap img {
  width: 100%;
}
.text-wrap {
    padding: 10px;
    margin: 0 20px;
    background: #f2f6fcb5;
}
</style>
